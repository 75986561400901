



/**
 * Init Left menu
 */

var _body = document.body;

function LeftMenu() {
	"use strict";

	$('.push-menu').on('click touch', function (e) {
		e.preventDefault();

		/// Init position
		// var body_class_init = 'c-left-menu--init';
		// _body.classList.add(body_class_init);

		// var body_class_init = 'c-left-menu--init';

		setTimeout(function () {

			var body_class = 'c-left-menu--opened';
			var element_class = 'c-left-menu--push';

			_body.classList.add(body_class);
			_body.classList.add(element_class);

			ShowMask();
			MenuClose(body_class, element_class);

		}, 100);

	});
}








function RightMenu() {
	"use strict";

	$('body').on('click touch','.init-right-menu', function (e) {
		e.preventDefault();


		var cart_nb = $(this).data('cartitems');

		if (cart_nb > 0) {

			setTimeout(function () {

				var body_class = 'c-right-menu--opened';
				var element_class = 'c-right-menu--push';

				_body.classList.add(body_class);
				_body.classList.add(element_class);

				ShowMask();
				MenuClose(body_class, element_class);



				new LazyLoad({elements_selector:".lazyload"}).loadAll();



			}, 100);


		} else {
			window.location = $(this).data('cart-link');
		}





	});
}




/**
 * Close Top, Left, Right menu
 */

function MenuClose($body, $element) {
	"use strict";

	$('.m-mask, .a-close-menu').on('click touch', function () {

		HideMask();

		if (_body.classList.contains($element)) {
			_body.classList.remove($element);
		}

		if (_body.classList.contains($body)) {
			_body.classList.remove($body);
		}

	});

}


/**
 * Hide Mask
 */

function HideMask() {
	"use strict";

	$('.m-mask').fadeOut(500, function () {
		$('.m-mask').remove();
	});

}


/**
 * Init Mask Background
 */

function ShowMask() {
	"use strict";

	var mask = '<div class="m-mask">';
	var mask_element = '.m-mask';

	$(mask).hide().appendTo('body').fadeIn('fast');
	$('body').find(mask_element).height($(document).height());

	$(window).resize(function () {
		$('body').find(mask_element).height($(document).height());
	});
}


$(document).ready(function () {
	"use strict";
	LeftMenu();
	RightMenu();
});







var btn_back = document.querySelectorAll('.btn-back');


if(btn_back.length > 0) {
	btn_back[0].addEventListener('click', function(){
		window.history.back();
	});
}


// console.log(prestashop);

prestashop.on('updatedCart', function(event) {

	if (typeof products_count !== 'undefined') {

		if (products_count < 1) {
			window.location.reload(true);
		}




	}

});

// prestashop.on('updateCart', function(event) {
//
// $('.product-quantity-spin').TouchSpin();
//
//
// });






// show-menu





// function LeftMenu() {
// 	"use strict";
//
// 	var _body = document.body;
// 	var body_class_init = 'c-left-menu--init';
//
// 	$('#rm-trigger').on('click touch', function (e) {
//
// 		if (_body.classList.contains(body_class_init)) {
// 			_body.classList.remove(body_class_init);
//
// 			$(this).find('img').attr('src', $(this).data('open'));
//
// 		} else {
// 			_body.classList.add(body_class_init);
//
// 			$(this).find('img').attr('src', $(this).data('close'));
// 		}
//
// 	});
//
// }
//
// LeftMenu();




function initSearchMobile() {
	"use strict";

	var searchElement = $('.b-search');
	var menuElement = $('.b-megamenu');
	var body = $('body');

	$('.js-init-search').on('click', function (e) {
		e.preventDefault();


		if (body.hasClass('search-opened')) {

			body.removeClass('search-opened');


			$(this).parents( ".header-top" ).find('.js-init-search i').toggleClass('icon-close icon-search');

			searchElement.hide(1,function(){
				menuElement.show();
				// searchElement.find('i').toggleClass('icon-close icon-search');
			});


			$('.js-search-close').on('click', function () {
				body.removeClass('search-opened');

				searchElement.hide(1,function(){
					searchElement.find('input[type="text"]').val('');
					menuElement.show();
				});
			});

		} else {

			body.toggleClass('search-opened');

			$(this).parents( ".header-top" ).find('.js-init-search i').toggleClass('icon-search icon-close');

			menuElement.hide(1,function(){
				searchElement.show();
				searchElement.find('i').toggleClass('icon-search icon-close');
				searchElement.find('input[type="text"]').focus();
			});




		}



	});

}


initSearchMobile();




